.footer-text {
  text-align: center;
  /* color: #868e96; */
  font-weight: bold;
  font-family: "Google Sans Regular";
}

.footer-beian {
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  color: #868e96;
  font-family: "Google Sans Regular";
}
.footer-div {
  margin-top: 2rem;
}
